<template>
  <div class="yimiao">
    <div>
      <div>
        <!-- <van-cell-group>
          <van-field
            v-model="datafrom.Mobile"
            label="联系电话"
            required
            placeholder="请输入联系电话"
          />
        </van-cell-group>

        <van-cell-group>
          <van-field
            v-model="datafrom.EvalContent"
            required
            label="评价内容"
            placeholder="请输入评价内容"
          />
        </van-cell-group> -->
        <div class="submit" style="padding: 10px 7px">
          <span
            style="
              color: red;
              font-size: 14px;
              letter-spacing: 8px;
              margin: 0px 5px;
            "
          ></span>
          <span style="color: #646566; font-size: 14px">整洁卫生</span>
          <div style="margin-left: 11%; display: inline-block">
            <van-rate
              v-model="datafrom.Health"
              :size="25"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
            />
          </div>
        </div>
        <div class="submit" style="padding: 10px 7px">
          <span
            style="
              color: red;
              font-size: 14px;
              letter-spacing: 8px;
              margin: 0px 5px;
            "
          ></span>
          <span style="color: #646566; font-size: 14px">描述相符</span>
          <div style="margin-left: 11%; display: inline-block">
            <van-rate
              v-model="datafrom.Match"
              :size="25"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
            />
          </div>
        </div>
        <div class="submit" style="padding: 10px 7px">
          <span
            style="
              color: red;
              font-size: 14px;
              letter-spacing: 8px;
              margin: 0px 5px;
            "
          ></span>
          <span style="color: #646566; font-size: 14px">交通位置</span>
          <div style="margin-left: 11%; display: inline-block">
            <van-rate
              v-model="datafrom.Traffic"
              :size="25"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
            />
          </div>
        </div>
        <div class="submit" style="padding: 10px 7px">
          <span
            style="
              color: red;
              font-size: 14px;
              letter-spacing: 8px;
              margin: 0px 5px;
            "
          ></span>
          <span style="color: #646566; font-size: 14px">安全程度</span>
          <div style="margin-left: 11%; display: inline-block">
            <van-rate
              v-model="datafrom.Secure"
              :size="25"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
            />
          </div>
        </div>
        <div class="submit" style="padding: 10px 7px">
          <span
            style="
              color: red;
              font-size: 14px;
              letter-spacing: 8px;
              margin: 0px 5px;
            "
          ></span>
          <span style="color: #646566; font-size: 14px">性价比</span>
          <div style="margin-left: 15%; display: inline-block">
            <van-rate
              v-model="datafrom.Cost"
              :size="25"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
            />
          </div>
        </div>
        <!-- <van-cell-group inset> -->
        <van-field
          v-model="datafrom.Detail"
          rows="1"
          autosize
          label="评价内容"
          type="textarea"
          placeholder="请输入评价内容"
        />
        <!-- </van-cell-group> -->
        <div class="submit" style="padding: 10px 7px">
          <span
            style="
              color: red;
              font-size: 14px;
              letter-spacing: 8px;
              margin: 0px 5px;
            "
          ></span>
          <span style="color: #646566; font-size: 14px">评价图片</span>
        </div>
        <van-uploader
          v-model="jkImageList"
          multiple
          capture="camera"
          class="imgs"
          :max-count="9"
          :after-read="afterRead"
          :before-delete="jkDelete"
          :deletable="true"
        />
        <!-- <van-cell-group>
          <van-field
            v-model="datafrom.Remark"
            label="备 注"
            placeholder="请输入备注"
          />
        </van-cell-group> -->
      </div>
      <!-- <div class="buttons">
          <van-button type="info" @click="postfrom()">提交</van-button>
        </div> -->
      <div class="buttons">
        <van-button type="info" style="margin: 10px 0" @click="postfrom()"
          >提交</van-button
        >
      </div>
    </div>
  </div>
</template>
  <script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
import config from "@/config";
import Compressor from "compressorjs";
import axios from "axios";
Vue.use(Toast);
import {
  WeGetRegionList,
  WeGetBuildRoomList,
  Wx_EvalPreHotel,
  WeGetAppletOrganList,
  GetOrgansNullToken,
} from "@/api/yeguang";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      // 健康码
      jkImageList: [],
      //行程码
      xcImageList: [],
      //核酸证明
      hsImageList: [],
      ridlist: [],
      BIdlist: [],
      BUIdlist: [],
      BFIdlist: [],
      BRIdlist: [],
      OrganList: [],
      diqu: [],
      guanxi: [],
      minzu: [],
      xveli: [],
      zhuangtai: [],
      hunyin: [],
      ocodelist: [],
      minDate: new Date(2021, 0),
      maxDate: new Date(2025, 0),
      minDateJc: new Date(2021, 0),
      NativeCantonCodedata: [],
      sexlist: [
        { id: "1", Name: "男" },
        { id: "2", Name: "女" },
      ],
      yimiaolist: [
        { id: "0", Name: "未接种" },
        { id: "1", Name: "第一针" },
        { id: "2", Name: "第二针" },
      ],
      idList: {
        organCode: "",
        RId: undefined,
      },
      datafrom: {
        Health: 0, // 整洁卫生评价
        Match: 0, // 描述相符评价
        Traffic: 0, // 交通位置评价
        Secure: 0, // 安全程度评价
        Cost: 0, // 性价比评价
        Detail: "", // 评价详情
        Imgs: "", // 评价图片、
        // Remark: "", // 备注、
      },
      idListRId: false,
      idListOrg: false,
      addshow: false,
      verifyshow: true,
      OCodeshow: false,
      sexshow: false,
      Nationshow: false,
      yimiao: false,
      datafromRelieveTime: false,
      datafromDivideTime: false,
      ImgsList: [],
    };
  },
  created() {
    // this.GetOrgansNullToken();
  },
  methods: {
    //评价图片
    afterRead(file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success(result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios.post(config.apiUploadUrl, formData, c).then((res) => {
            if (res.data.code === 0) {
              console.log(res.data);
              // that.datafrom.HealthCode = res.data.data
              that.ImgsList.push(res.data.picurl[0]);
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          });
        },
      });
    },
    //删除评价图片
    jkDelete(file, detail) {
      var that = this;
      that.ImgsList.splice(detail.index, 1);
      this.jkImageList.splice(detail.index, 1);
    },
    // 提交
    postfrom: function () {
      var that = this;
      console.log(that.datafrom);
      //身份证号验证
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      // 手机号验证
      //   var reg_tel =
      //     /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

      //   if (this.datafrom.Mobile == "") {
      //     Toast.fail("请输入联系电话");
      //     return false;
      //   } else {
      //     if (!reg_tel.test(this.datafrom.Mobile)) {
      //       Toast.fail("请输入正确的联系电话");
      //       return false;
      //     }
      //   }
      if (this.datafrom.Health == 0) {
        Toast.fail("请评价整洁卫生");
        return false;
      }
      if (this.datafrom.Match == 0) {
        Toast.fail("请评价是否与描述相符");
        return false;
      }
      if (this.datafrom.Traffic == 0) {
        Toast.fail("请评价交通位置是否便利");
        return false;
      }
      if (this.datafrom.Secure == 0) {
        Toast.fail("请评价安全程度");
        return false;
      }
      if (this.datafrom.Cost == 0) {
        Toast.fail("请评价性价比");
        return false;
      }
      this.datafrom.OpenID = getOpenId();
      //   this.datafrom.ProSerId = Number(this.$route.params.ProSerId);
      this.datafrom.PreId = Number(this.$route.params.preId);
      this.datafrom.Imgs = that.ImgsList + "";
      Wx_EvalPreHotel(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            this.$router.go(-1);
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败!" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
    gitocodelist() {
      WeGetAppletOrganList().then((res) => {
        this.ocodelist = res.data.data;
      });
    },
    // 社区列表
    GetOrgansNullToken: function (row) {
      GetOrgansNullToken({ level: 4, kind: 1 }).then((res) => {
        this.OrganList = res.data.data;
      });
    },
    // 小区(组)列表
    RegionList: function (row) {
      WeGetRegionList({ organCode: row }).then((res) => {
        this.ridlist = res.data.data;
      });
    },
    // 房屋列表
    BuildRoomList: function (val) {
      WeGetBuildRoomList({
        bId: this.idList.BId,
        buId: this.idList.buId,
        bfId: val,
      }).then((res) => {
        console.log(res);
        this.BRIdlist = res.data.data;
      });
    },
    fanhui: function () {
      this.$router.push({
        name: "register",
      });
    },
    // 社区选择器
    onidListOrg(val) {
      this.datafrom.OrgCode = val.organcode;
      this.datafrom.OrgName = val.organname;
      this.idListOrg = false;
      this.RegionList(val.organcode);
      this.datafrom.Region = "";
      // this.BuildList(val.RId);
    },
    // 小区(组)选择器
    onidListRId(val) {
      this.datafrom.RId = val.RId;
      this.datafrom.Region = val.Title;
      this.idListRId = false;
      this.BuildList(val.RId);
    },
    // 性别选择器
    onsexshow(val) {
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
      this.sexshow = false;
    },
    // 疫苗选择器
    onyimiao(val) {
      this.datafrom.yimiao = val.id;
      this.datafrom.yimiaoName = val.Name;
      this.yimiao = false;
    },
    // 隔离时间
    ondatafromDivideTime(val) {
      console.log(val);
      this.datafrom.DivideTime = this.dateformat(val);
      this.datafromDivideTime = false;
      this.minDateJc = new Date(this.datafrom.DivideTime);
      this.datafrom.RelieveTime = "";
    },
    // 解除时间
    ondatafromRelieveTime(val) {
      this.datafrom.RelieveTime = this.dateformat(val);
      if (this.datafrom.DivideTime > this.datafrom.RelieveTime) {
        Toast.fail("解除时间不能早于隔离时间");
        return;
      } else {
        this.datafrom.RelieveTime = this.dateformat(val);
      }

      this.datafromRelieveTime = false;
    },

    // 社区
    onocode(val) {
      console.log(val);
      this.datafrom.OrgName = val.selectedOptions[0].OrganName;
      this.RegionList(val.value);
      this.OCodeshow = false;
    },
    dateformat(val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let houser = val.getHours();
      let Minutes = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (houser >= 1 && houser <= 9) {
        houser = `0${houser}`;
      }
      if (Minutes >= 1 && Minutes <= 9) {
        Minutes = `0${Minutes}`;
      }
      return `${year}-${month}-${day} ${houser}:${Minutes}`;
    },
  },
};
</script>
  <style>
.imgs {
  margin: 0 15px;
}
.yimiao .inputFrom {
  margin: 15px 8%;
}
.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.yimiao .buttons {
  text-align: center;
}
.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>